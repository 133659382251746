import React from 'react';
import styled from 'styled-components';

interface InputFieldProps {
    iconSrc: string;
    type: string;
    placeholder: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputField: React.FC<InputFieldProps> = ({ iconSrc, type, placeholder, value, onChange }) => {
    return (
        <InputWrapper>
            <Icon src={iconSrc} alt="Icon" />
            <TextInput
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            />
        </InputWrapper>
    );
};

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.03);
  border-radius: 16px;
  margin-bottom: 15px;
  padding: 18px 15px;
  position: relative;
`;

const Icon = styled.img`
  height: 20px;
  margin-right: 10px;
`;

const TextInput = styled.input`
  background: transparent;
  border: none;
  color: #fff;
  flex-grow: 1;
  outline: none;
  font-size: 16px;

  &::placeholder {
    color: #8a8e9e;
    font-family: 'Inter', sans-serif;
  }
`;

export default InputField;
