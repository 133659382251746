import React from 'react';
import styled from 'styled-components';

interface SignUpLinkProps {
    text: string;
    linkText: string;
    onLinkClick: () => void;
}

const SignUpLink: React.FC<SignUpLinkProps> = ({ text, linkText, onLinkClick }) => {
    return (
        <SignUpWrapper>
            {text} <SignUp onClick={onLinkClick}>{linkText}</SignUp>
        </SignUpWrapper>
    );
};

const SignUpWrapper = styled.div`
  font-size: 14px;
  color: #8a8e9e;
  font-family: 'Inter', sans-serif;
`;

const SignUp = styled.span`
  color: #357cf8;
  cursor: pointer;
  font-weight: 500;
  margin-left: 5px;
  font-family: 'Inter', sans-serif;
`;

export default SignUpLink;
