import axios, {AxiosResponse} from 'axios';

const login_endpoint = 'https://api.oblix.tech/login';

export async function login(username: string, password: string, redirect_url: string = ""): Promise<string> {
    console.log("Trying to login");
    let response: AxiosResponse;
    try {
        const requestParams: { [p:string] : string }= {
            username: username,
            password: password
        };
        if (redirect_url) {
            requestParams['redirect_uri'] = redirect_url;
            console.log(requestParams);
        }
        response = await axios.post(
            login_endpoint,
            requestParams,
            {withCredentials: true, validateStatus: (status) => status === 200 || status === 303});
        console.log(`Response: ${response.status}`);
    } catch (e: any) {
        console.error(e);
        if (e.response.status === 401) {
            throw new Error("Invalid username or password");
        }
        else {
            throw new Error("Login failed");
        }
    }
    if (response.status === 200) {
        return "Login successful";
    }
    if (response.status === 303) {
        console.log(response.data);
        console.log(response.headers);
        window.location.href = response.data;
        return "Redirecting to application";
    }
    else if (response.status === 401) {
        throw new Error("Invalid username or password");
    }
    else {
        throw new Error("Login failed");
    }
}