import React from 'react';
import styled from 'styled-components';

interface LoginFormProps {
    title: string;
    subtitle: string;
    children: React.ReactNode;
}

const LoginForm: React.FC<LoginFormProps> = ({ title, subtitle, children }) => {
    return (
        <LoginContainer>
            <Title>{title}</Title>
            <SubTitle dangerouslySetInnerHTML={{ __html: subtitle }} />
            {children}
        </LoginContainer>
    );
};

const LoginContainer = styled.div`
    border-radius: 32px;
    width: 100%;
    max-width: 400px;
`;

const Title = styled.h1`
    font-size: 28px;
    color: #fff;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
`;

const SubTitle = styled.h2`
    font-size: 16px;
    color: #8A8E9E;
    font-weight: 400;
    margin-bottom: 50px;
    font-family: 'Inter', sans-serif;
`;

export default LoginForm;