import React, { useEffect, useState } from "react";
import styled from "styled-components";
import tinycolor from "tinycolor2";

interface WideButtonProps {
    text: string;
    className?: string;
    size?: string;
    radius?: string;
    color?: string;
    opacity?: number;
    textColor?: string;
    fontSize?: string;
    fontWeight?: string;
    onClick: () => void;
}

function WideButton({
                        text,
                        className,
                        size = "90px",
                        radius = "100px",
                        color = "#1640D6",
                        opacity = 1,
                        textColor = "#FFFFFF",
                        fontSize = "16px",
                        fontWeight = "normal",
                        onClick
                    }: WideButtonProps) {
    const [isActive, setIsActive] = useState(false);
    const [isTouchDevice, setIsTouchDevice] = useState(false);

    useEffect(() => {
        setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0);
    }, []);

    const varColor = tinycolor(color);
    varColor.setAlpha(!isActive ? opacity : opacity * 5);

    const handleTouchStart = () => {
        setIsActive(true);
    };

    const handleTouchEnd = () => {
        setIsActive(false);
        onClick();
    };

    const handleMouseEnter = () => {
        if (!isTouchDevice) setIsActive(true);
    };

    const handleMouseLeave = () => {
        if (!isTouchDevice) setIsActive(false);
    };

    const handleClick = (e: any) => {
        e.preventDefault();
        console.log("Button clicked");
        if (!isTouchDevice) onClick();
    };

    return (
        <div className={className}>
            <Button
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                onClick={handleClick}
                $isTouchDevice={isTouchDevice}
                $color={color}
                $size={size}
                $radius={radius}
                $textColor={textColor}
                $fontSize={fontSize}
                $fontWeight={fontWeight}
            >
                {text}
            </Button>
        </div>
    );
}

const Button = styled.button<{$color: string, $isTouchDevice: boolean, $radius: string, $size: string, $textColor: string, $fontSize: string, $fontWeight: string}>`
    border: none;
    padding: 17px;
    background-color: ${props => props.$color};
    border-radius: ${props => props.$radius};
    width: ${props => props.$size};
    height: auto;
    min-height: 34px;
    transition: background-color 0.3s, opacity 0.3s;
    color: ${props => props.$textColor};
    font-size: ${props => props.$fontSize};
    font-weight: ${props => props.$fontWeight};
    font-family: 'Inter', sans-serif;
    cursor: pointer;


    ${props => !props.$isTouchDevice && `
    &:hover {
      background-color: ${tinycolor(props.$color).darken(10).toString()};
      opacity: 0.8;
    }
  `}

    &:active {
        background-color: ${props => tinycolor(props.$color).darken(20).toString()};
        opacity: 0.9;
    }
`;

export default WideButton;