import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import LoginForm from './components/LoginForm';
import InputField from './components/InputField';
import SocialLogin from './components/SocialLogin';
import WideButton from './components/WideButton';
import SignUpLink from './components/SignUpLink';
import Background from './FullBackground.svg';
import UserIcon from './icons/UserIcon.svg';
import LockIcon from './icons/LockIcon.svg';
import { useSearchParams } from 'react-router-dom';
import { login } from './proxy/LoginProxy';

function App() {
    const [searchParams] = useSearchParams();
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [loginStatus, setLoginStatus] = React.useState('');
    const navigate = useNavigate();

    const loginHandler = async () => {
        try {
            const redirect_uri = searchParams.get('redirect_uri');
            await login(username, password, redirect_uri ? redirect_uri : '');
        } catch (e: any) {
            setLoginStatus(e.message);
        }
    };

    const handleSignUpClick = () => {
        navigate('/signup');
    };

    return (
        <StyledApp>
            <LoginContainer>
                <LoginForm title="Welcome!" subtitle="Sign in to OBX Dash">
                    <InputField
                        iconSrc={UserIcon}
                        type="text"
                        placeholder="Your username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <InputField
                        iconSrc={LockIcon}
                        type="password"
                        placeholder="Your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <ForgotPasswordLink>Forgot Password?</ForgotPasswordLink>
                    {loginStatus && <ErrorMessage>{loginStatus}</ErrorMessage>}
                </LoginForm>
                <StyledLoginButton size={"159px"} text={"Sign in"} onClick={loginHandler} />
                <Separator>
                    <Line />
                    <OrText>Or</OrText>
                    <Line />
                </Separator>
                <SocialLogin />
                <SignUpLink
                    text="Don’t have an account?"
                    linkText="Sign up"
                    onLinkClick={handleSignUpClick}
                />
            </LoginContainer>
        </StyledApp>
    );
}

const StyledApp = styled.div`
    text-align: center;
    background: url('${Background}');
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
`;

const LoginContainer = styled.div`
    background-color: rgba(255, 255, 255, 0.02);
    border-radius: 32px;
    padding: 40px;
    width: 90%;
    max-width: 400px;
`;
const ForgotPasswordLink = styled.a`
    font-size: 14px;
    color: #8a8e9e;
    font-family: 'Inter', sans-serif;
    text-align: right;
    margin-bottom: 20px;
    display: block;
    cursor: pointer;
`;
const ErrorMessage = styled.span`
    color: #ff6b6b;
    font-size: 14px;
`;
const StyledLoginButton = styled(WideButton)`
    width: 100%;
    color: white;
    padding: 12px 0;
    border-radius: 20px;
    font-size: 18px;
`;
const Separator = styled.div`
    display: flex;
    align-items: center;
    margin: 20px 0;
`;
const Line = styled.div`
    flex-grow: 1;
    height: 1px;
    background-color: #8a8e9e;
`;
const OrText = styled.span`
    padding: 0 10px;
    color: #8a8e9e;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
`;

export default App;