import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import LoginForm from '../components/LoginForm';
import InputField from '../components/InputField';
import SocialLogin from '../components/SocialLogin';
import WideButton from '../components/WideButton';
import SignUpLink from '../components/SignUpLink';
import Background from '../FullBackground.svg';
import UserIcon from '../icons/UserIcon.svg';
import LockIcon from '../icons/LockIcon.svg';
import KeyIcon from '../icons/KeyIcon.svg';

function SignUp() {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [accessKey, setAccessKey] = React.useState('');
    const [signUpStatus, setSignUpStatus] = React.useState('');
    const navigate = useNavigate();

    const signUpHandler = async () => {
        if (password !== confirmPassword) {
            setSignUpStatus('Passwords do not match');
            return;
        }

        try {
            // Add sign-up logic here
        } catch (e: any) {
            setSignUpStatus(e.message);
        }
    };

    const handleLoginClick = () => {
        navigate('/');
    };

    return (
        <StyledApp>
            <SignUpContainer>
                <LoginForm title="Sign up" subtitle="Welcome to OBX Dash!<br />Let's begin your journey">
                    <InputField
                        iconSrc={UserIcon}
                        type="text"
                        placeholder="Your E-mail"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <InputField
                        iconSrc={LockIcon}
                        type="password"
                        placeholder="Your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <InputField
                        iconSrc={LockIcon}
                        type="password"
                        placeholder="Confirm password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <InputField
                        iconSrc={KeyIcon}
                        type="text"
                        placeholder="Access Key"
                        value={accessKey}
                        onChange={(e) => setAccessKey(e.target.value)}
                    />
                    {signUpStatus && <ErrorMessage>{signUpStatus}</ErrorMessage>}
                </LoginForm>
                <StyledSignUpButton size={"159px"} text={"Sign up now"} onClick={signUpHandler} />
                <Separator>
                    <Line />
                    <OrText>Or</OrText>
                    <Line />
                </Separator>
                <SocialLogin />
                <SignUpLink
                    text="Already have an account?"
                    linkText="Log in"
                    onLinkClick={handleLoginClick}
                />
            </SignUpContainer>
        </StyledApp>
    );
}

const StyledApp = styled.div`
    text-align: center;
    background: url('${Background}');
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
`;

const SignUpContainer = styled.div`
    background-color: rgba(255, 255, 255, 0.02);
    border-radius: 32px;
    padding: 40px;
    width: 90%;
    max-width: 400px;
`;

const ErrorMessage = styled.span`
    color: #ff6b6b;
    font-size: 14px;
`;

const StyledSignUpButton = styled(WideButton)`
    width: 100%;
    color: white;
    padding: 12px 0;
    border-radius: 20px;
    font-size: 18px;
    cursor: pointer;
`;

const Separator = styled.div`
    display: flex;
    align-items: center;
    margin: 20px 0;
`;

const Line = styled.div`
    flex-grow: 1;
    height: 1px;
    background-color: #8a8e9e;
`;

const OrText = styled.span`
    padding: 0 10px;
    color: #8a8e9e;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
`;

export default SignUp;