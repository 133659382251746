import React from 'react';
import styled from 'styled-components';
import FaceBookIcon from '../icons/FacebookIcon.svg';
import AppleIcon from '../icons/AppleIcon.svg';
import GoogleIcon from '../icons/GoogleIcon.svg';


const SocialLogin: React.FC = () => {
    return (
        <SocialLoginContainer>
            <SocialIcon src={FaceBookIcon} alt="Facebook" />
            <SocialIcon src={AppleIcon} alt="Apple" />
            <SocialIcon src={GoogleIcon} alt="Google" />
        </SocialLoginContainer>
    );
};

const SocialLoginContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
`;

const SocialIcon = styled.img`
  height: 40px;
  cursor: pointer;
`;

export default SocialLogin;
